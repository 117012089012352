import { Card, Row, Col, Avatar, Tag, Typography, List, Calendar } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { LuLayoutDashboard, LuUser } from 'react-icons/lu';
import ContainerCard from 'src/components/ContainerCard';
import avatar from 'src/assets/icons/avatar.jpg';
import Progress from './components/Progress';
import UserSource from './components/UserSource';

const { Text, Link } = Typography;

export default function Workplace() {
  const { t } = useTranslation();
  // const userInfo = useSelector((state: RootState) => state.user.user);

  function createGreetings(nickname?: string) {
    const hour = dayjs().hour();
    if (hour > 0 && hour < 6) {
      return `${t('workplace.early')}${nickname} ${t('workplace.early_desc')}`;
    }
    if (hour >= 6 && hour < 11) {
      return `${t('workplace.morning')}${nickname} ${t('workplace.morning_desc')}`;
    }
    if (hour >= 11 && hour < 14) {
      return `${t('workplace.noon')}${nickname} ${t('workplace.noon_desc')}`;
    }
    if (hour >= 14 && hour < 18) {
      return `${t('workplace.afternoon')}${nickname} ${t('workplace.afternoon_desc')}`;
    }
    if (hour >= 18 && hour < 22) {
      return `${t('workplace.evening')}${nickname} ${t('workplace.evening_desc')}`;
    }
    if (hour >= 22 && hour < 24) {
      return `${t('workplace.night')}${nickname} ${t('workplace.night_desc')}`;
    }
  }

  return (
    <div className="m-[16px] rounded-md min-[calc(100vh - 32px)]">
      <Row gutter={16}>
        <Col span={24} xl={18}>
          <Card>
            <div className="text-lg mb-[20px]">{t('menu.workplace')}</div>
            <div className="flex justify-between">
              <div className="flex items-center">
                <Avatar className="w-[64px] flex-none" size={64} src={avatar} />
                <div className="ml-[16px]">
                  <div className="text-lg">{createGreetings('lava')}</div>
                  <div className="text-sm text-gray-600 dark:text-gray-400 mt-[4px]">
                    今日晴🔆，15℃ - 20℃!
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Row gutter={16} className="mt-[16px]">
            <Col span={24} lg={14} className="mb-[16px]">
              <ContainerCard title={t('workplace.progress')} className="h-[355px]">
                <Progress />
              </ContainerCard>
            </Col>
            <Col span={24} lg={10}>
              <ContainerCard title={t('workplace.dynamics')} className="h-[355px]">
                <List
                  itemLayout="horizontal"
                  dataSource={[
                    { title: 'lava 发布了第二个提交', desc: '2022-01-08' },
                    { title: 'lava 发布了第一个提交', desc: '2022-01-07' },
                    { title: '在2024年1月4日创建前端项目 lava-admin!', desc: '2022-01-04' },
                    { title: '在2024年1月1日创建开源项目 lava!', desc: '2022-01-01' },
                  ]}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        avatar={<Avatar src={avatar} />}
                        title={item.title}
                        description={item.desc}
                      />
                    </List.Item>
                  )}
                />
              </ContainerCard>
            </Col>
          </Row>
          <ContainerCard title={t('workplace.source')}>
            <UserSource />
          </ContainerCard>
        </Col>
        <Col span={0} xl={6}>
          <ContainerCard
            className="mb-[16px]"
            title={t('workplace.quick')}
            extra={<a href="/">{t('workplace.manage')}</a>}>
            <div className="flex justify-start mt-[8px]">
              <div className="w-1/4 flex flex-col justify-center items-center hover:text-gray-400 dark:text-gray-500 cursor-pointer">
                <div className="w-[48px] h-[48px] rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <LuUser size={24} />
                </div>
                用户管理
              </div>
              <div className="w-1/4 flex flex-col justify-center items-center hover:text-gray-400 dark:text-gray-500 cursor-pointer">
                <div className="w-[48px] h-[48px] rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <LuLayoutDashboard size={24} />
                </div>
                数据看板
              </div>
            </div>
          </ContainerCard>
          <ContainerCard className="mb-[16px]" title={t('workplace.schedule')}>
            <Calendar fullscreen={false} />
          </ContainerCard>
          <ContainerCard
            className="mb-[16px]"
            title={t('workplace.notice')}
            extra={<a href="/">{t('workplace.more')}</a>}>
            <div className="flex justify-start items-center my-[8px]">
              <Tag color="warning">通知</Tag>
              <Text ellipsis={true}>lava 就要上线啦！</Text>
            </div>
            <div className="flex justify-start items-center my-[8px]">
              <Tag color="error">紧急</Tag>
              <Text ellipsis={true}>这是一条紧急消息，lava 就要上线啦！</Text>
            </div>
            <div className="flex justify-start items-center my-[8px]">
              <Tag color="processing">活动</Tag>
              <Text ellipsis={true}>lava 就要上线啦！</Text>
            </div>
            <div className="flex justify-start items-center my-[8px]">
              <Tag color="warning">通知</Tag>
              <Text ellipsis={true}>lava 就要上线啦！</Text>
            </div>
          </ContainerCard>
          <ContainerCard
            className="mb-[16px]"
            title={t('workplace.help')}
            extra={<a href="/">{t('workplace.more')}</a>}>
            <div className="my-[8px]">
              <Link>React 官网文档</Link>
            </div>
            <div className="my-[8px]">
              <Link>React redux 官网文档</Link>
            </div>
            <div className="my-[8px]">
              <Link>antd 官网文档</Link>
            </div>
          </ContainerCard>
        </Col>
      </Row>
    </div>
  );
}
